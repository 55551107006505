<template>
  <div>
    <div class="fixBottom1">
      <div class="bwhite">
        <div class="blogo" @click="goindex">
          <img src="../assets/images/xuexi/logo.png" />
        </div>
        <div class="blogodesc">
          Accelerate Mass Adoption of RWA Tokenization
        </div>
        <div class="blianxibtn">
          <img src="../assets/images/index/faq.png" @click="goknowledge" />
        </div>
        <div class="xbtn">
          <img src="../assets/images/index/x.png" @click="gotuite" />
        </div>
        <div class="inbtn">
          <img src="../assets/images/index/in.png" @click="goin" />
        </div>
        <!-- <div class="mbtn">
          <img src="../assets/images/index/m.jpg" @click="gom" />
        </div> -->
        <div class="bshuxian"></div>
        <div class="bshuxian2"></div>

        <div class="bshu1">
          <div class="shutitle">ABOUT</div>
          <div class="shuContent">
            <a
              @click="
                $router.push({
                  name: 'aboutdmz',
                })
              "
              >About DMZ
            </a>
            <a
              @click="
                $router.push({
                  name: 'team',
                })
              "
              >Management Team</a
            >
          </div>
        </div>

        <div class="bshu2">
          <div class="shutitle">LEGAL</div>
          <div class="shuContent">
            <a
              target="_blank"
              :href="href + 'file/DMZWebsiteTerms&Conditions20240313.pdf'"
              >Terms and Conditions</a
            >
            <a
              target="_blank"
              :href="href + 'file/DMZPrivacyPolicy20240306.pdf'"
              >Privacy</a
            >
            <a
              target="_blank"
              :href="href + 'file/DMZCookiesPolicy20240306.pdf'"
              >Cookie Policy</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="fixBottom2">
      <div class="bblue">
        DMZ.Finance All rights reserved. @2024
        <br />
      </div>
    </div>

    <div class="fixCookie" v-if="cookieShow" ref="fixcookie">
      <div class="safeW">
        <div class="tiaokuantxt">
          By using this website, you agree to
          <a target="_blank" :href="href + 'file/DMZPrivacyPolicy20240306.pdf'"
            >our Privacy Policy</a
          >
          and
          <a target="_blank" :href="href + 'file/DMZCookiesPolicy20240306.pdf'"
            >Terms of Use</a
          >
        </div>
        <div class="tongyi" @click="tongyi">Agree</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavButton',

  data () {
    return {
      cookieShow: true,
      href: window.location.origin+'/',
    }
  },

  mounted () {
    console.log(this.href)
    if (localStorage.getItem('agree') == 1) {
      this.cookieShow = false
      $('.fixBottom2>.bblue').height(80)
    } else {
      this.cookieShow = true
    }

    // const isFox = navigator.userAgent.indexOf('Firefox') > -1 ? true : false
    // var that = this;
    // this.$nextTick(()=>{
    //   console.log('fixcookie',$('body').children('.fixCookie').length);

    //   if(isFox){
    //     $('body').children('.fixCookie').remove();
    //     document.body.appendChild(that.$refs.fixcookie);
    //     that.$refs.fixcookie.style.transform = `scale(0.8)`
    //     $('.fixCookie').css('transform-origin','left bottom')
    //     $('.fixCookie').css('width','1900px')
    //   }

    //   if (localStorage.getItem('agree') == 1) {
    //     that.cookieShow = false
    //     $('.fixBottom2>.bblue').height(80)
    //   } else {
    //     that.cookieShow = true
    //   }

    // })
  },

  methods: {
    tongyi () {
      localStorage.setItem('agree', 1)
      this.cookieShow = false
      $('.fixBottom2>.bblue').height(80)
    },

    goknowledge () {
      this.$router.push({
        path: '/knowledge',
      })
    },
    goindex () {
      this.$router.push({
        path: '/index',
      })
    },
    gom () {
      window.open('https://medium.com/@dmz_finance')
    },
    golocal () {
      window.open('http://localhost:8081/#/my/chainupBack')
    },
    gotuite () {
      window.open('https://x.com/DMZ_Finance')
    },
    goin () {
      window.open('https://www.linkedin.com/company/dmz-finance/')

    }
  },
}
</script>

<style lang="less" scoped>
.fixBottom1 {
  width: 100%;
  background: #000;

  .bwhite {
    width: 1200px;
    margin: 0 auto;
    height: 363px;
    position: relative;
  }
}

.fixBottom2 {
  width: 100%;
  background: #000;
  .bblue {
    border-top: 1px solid #707070;
    width: 1200px;
    margin: 0 auto;
    height: 113px;
    line-height: 20px;
    position: relative;
    font-size: 16px;
    color: #fff;
    box-sizing: border-box;
    padding-top: 30px;
  }
}

.blogo {
  position: absolute;
  left: 10px;
  top: 50px;
  cursor: pointer;
  > img {
    width: 100px;
  }
}

.blogodesc {
  position: absolute;
  left: 10px;
  top: 100px;
  font-size: 14px;
  color: #fff;
}

.blianxibtn {
  position: absolute;
  left: 10px;
  top: 190px;
  > img {
    cursor: pointer;
    width: 75px;
  }
}
.xbtn {
  position: absolute;
  left: 10px;
  top: 250px;
  > img {
    cursor: pointer;
    width: 24px;
  }
}
.inbtn {
  position: absolute;
  left: 60px;
  top: 250px;
  > img {
    cursor: pointer;
    width: 24px;
  }
}
.mbtn {
  position: absolute;
  left: 110px;
  top: 250px;
  > img {
    cursor: pointer;
    width: 24px;
  }
}

.bshu1 {
  position: absolute;
  left: 680px;
  top: 50px;
}

.bshu2 {
  white-space: nowrap;
  position: absolute;
  left: 1050px;
  top: 50px;
}

.bshu3 {
  position: absolute;
  left: 950px;
  top: 50px;
  white-space: nowrap;
}

.bshu4 {
  position: absolute;
  left: 630px;
  top: 50px;
}

.shutitle {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}

.shuContent {
  > a {
    display: block;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 30px;
  }
}

.shuContent a:hover {
  text-decoration: underline;
}

.bshuxian {
  position: absolute;
  left: 950px;
  top: 50px;
  width: 1px;
  height: 200px;
  background: #bebebe;
}

.bshuxian2 {
  position: absolute;
  left: 600px;
  top: 50px;
  width: 1px;
  height: 200px;
  background: #bebebe;
}

.faq {
  position: absolute;
  left: 280px;
  top: 50px;
  cursor: pointer;
  > img {
    width: 82px;
  }
}

.fixCookie {
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #4ed445;
  .safeW {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
}

.tiaokuantxt {
  position: absolute;
  left: 10px;
  top: 20px;
  color: #000;
  font-size: 18px;
  a {
    color: #000;
    text-decoration: underline;
  }
}

.tongyi {
  position: absolute;
  right: 0px;
  top: 13px;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background: #fff;
  text-align: center;
  font-size: 18px;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
a {
  cursor: pointer;
}
</style>
