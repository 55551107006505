<template>
  <div>
    <div class="address" v-show="maskfromAddress">
      Address: {{ maskfromAddress }}
      <i
        class="el-icon-copy-document copy"
        :data-clipboard-text="walletAdress"
        style="cursor: pointer"
      ></i>
    </div>
    <img
      v-show="!maskfromAddress"
      src="../assets/images/header/connectwallet.png"
      alt=""
      class="img224"
      @click="connectMetamaskFn"
    />
  </div>
</template>

<script>
import Web3_Api from '@/utils/wallet/index'
import { mapState } from 'vuex'
import { getConfigData ,changeWalletDst} from '@/api/home'
import Clipboard from 'clipboard'

export default {
  data() {
    return {
      ethereum: null,
      walletMethods: null,
      walletAdress: [],
      eth: '',
      config: {},
      maskfromAddress: '',
    }
  },
  initEthereum() {
    window.ethereum = this.ethereum
  },
  mounted() {
    new Clipboard('.copy')

    this.ethereum = window.ethereum
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const res = await getConfigData()
      this.config = res.data.web3_config
    },
    initEthereum() {
      window.ethereum = this.ethereum
    },
    async connectMetamaskFn() {
      this.initEthereum()
      this.walletMethods = new Web3_Api.Metamask()
      this.walletAdress = await this.walletMethods.connectWallet()

      if (this.walletAdress !== this.wallet_address) {
        this.$Message.error('Your wallet is not whitelisted')
      }
      this.maskfromAddress = this.maskString(this.walletAdress)
      const res = await this.walletMethods.connectWallet()
      this.$store.commit('setUserAddress', res)
      const res2 = await this.walletMethods.getAllowance(
        this.config.contract_token_abi_file,
        this.config.contract_token_address
      )
      changeWalletDst(res2*Math.pow(10, 8))
      console.log(res2,'res222222')
      this.$store.commit('setDST', res2)
    },

    maskString(str) {
      return str.slice(0, 6) + '...' + str.slice(-4)
    },
  },
  computed: {
    ...mapState(['wallet_address', 'userAddress']),
  },
}
</script>

<style lang="less" scoped>
.img224 {
  width: 190px;

  cursor: pointer;
  //   margin-right: 39px;
}
.address {
  padding: 0 20px;
  text-align: center;
  height: 55px;
  background: linear-gradient(27deg, #19dd19 0%, #b4ffb4 100%);
  border-radius: 32px 32px 32px 32px;
  line-height: 55px;
  color: #000;
  font-size: 16px;
}
</style>
