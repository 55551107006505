import { getMessages } from '@/api/home'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token') || '',
    userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
    unReadMessage: [],
    total_count: 0,
    userAddress: '',
    wallet_address: sessionStorage.getItem('wallet_address') || '',
    dst: '0',
    cateG:sessionStorage.getItem('categ') || 0,
    emailx:sessionStorage.getItem('email') || ''
  },
  getters: {
    getUser: (state) => {
      return state.userInfo
    },
  },
  mutations: {
    setEmail(state,value){
      state.emailx = value
    },

    setCateG(state,value){
      state.cateG = value
    },

    setDST(state, dst) {
      state.dst = dst
    },

    setWalletAddress: (state, address) => {
      console.log('setWalletAddress', address)
      sessionStorage.setItem('wallet_address', address)
      state.wallet_address = address
    },
    setUserAddress: (state, address) => {
      console.log('userAddress', address)
      state.userAddress = address
    },

    SET_TOKEN: (state, token) => {
      state.token = token
      sessionStorage.setItem('token', token)
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    REMOVE_INFO: (state) => {
      localStorage.setItem('token', '')
      sessionStorage.setItem('userInfo', JSON.stringify(''))
      state.userInfo = {}
    },
    setUnreadMessage(state, payload) {
      state.unReadMessage = payload
    },
    setTotalCount(state, payload) {
      state.total_count = payload
    },
  },
  actions: {
    asyncSetMessage({ commit }) {
      getMessages(1, 5, 1).then((res) => {
        commit('setUnreadMessage', res.data.messages)
        commit('setTotalCount', res.data.total_count)
      })
    },
  },
  modules: {},
})
