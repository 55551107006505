<template>
  <div ref="message" class="lx-message" :class="type">
    <div>
      <i
        class="el-icon-success"
        v-show="type === 'success'"
        style="color: #68da47"
      ></i>
      <i class="el-icon-error" v-show="type === 'error'" style="color: red"></i>
    </div>
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageComponent',
  data() {
    return {
      message: '',
      // 类型
      type: '',
      // 事件
      duration: 3000,
      // 计时器
      timer: null,
    }
  },
  computed: {
    // 动态添加类名
    iconClass() {
      // 成功
      if (this.type === 'success') {
        return `icon-correct`
        // 错误
      } else if (this.type === 'error') {
        return `icon-error`
        // 警告
      } else if (this.type === 'warning') {
        return `icon-jinggao-warning`
        // 加载
      } else if (this.type === 'loading') {
        return `icon-refresh rotate`
      }
      return ``
    },
    messageClass() {
      if (this.type === 'success') {
        return `lx-message-content`
      } else if (this.type === 'error') {
        return `lx-message-error`
      } else if (this.type === 'warning') {
        return `lx-message-warning`
      } else if (this.type === 'loading') {
        return `icon-message-refresh`
      }
      return ``
    },
  },
  methods: {
    // 打开的方法
    showMessage(message, type, duration) {
      if (type === 'success') {
        this.type = type
        this.message = message || '这是一条成功消息' // 不传message就是默认值
        this.duration = duration || 2000
      } else if (type === 'error') {
        this.type = type
        this.message = message || '这是一条失败消息'
        this.duration = duration || 2000
      } else if (type === 'warning') {
        this.type = type
        this.message = message || '这是一条警告消息'
        this.duration = duration || 2000
      } else if (type === 'loading') {
        this.type = type
        this.message = message || '这是一条加载消息'
        this.duration = duration || 2000
      }
      // 过渡
      this.$refs.message.style.top = '15%'
      if (this.timer) {
        this.timer = clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.closeMessage()
      }, this.duration)
    },
    // 关闭的方法
    closeMessage() {
      if (this.timer) {
        this.timer = clearTimeout(this.timer)
      }
      // 过渡
      this.$refs.message.style.top = '0'
    },
  },
}
</script>

<style scoped></style>
<style scoped lang="less">
.lx-message {
  color: #000;
  font-size: 25px;

  max-width: 70%;
  position: fixed;
  // height: 25px;
  // display: flex;
  // align-items: center;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 20px 50px;
  border-radius: 20px;
  text-align: center;
  border-color: #b9c0c4;
  border: 1px solid transparent;
  transition: 0.55s;
  z-index: 99999;
  span {
    margin-left: 5px;
  }
  i {
    &.rotate {
      animation: moveicon 1.5s infinite linear;
    }
  }
}
@keyframes moveicon {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.success {
  background: #fff;
}
.error {
  background: #fff;
}
.icon-correct {
  color: #19be6b;
  font-size: 22px;
}
.lx-message-content {
  color: #19be6b;
}
.icon-error {
  color: #ee3c10;
  font-size: 22px;
}
.lx-message-error {
  color: #ed3f13;
}
.icon-jinggao-warning {
  color: #ff9900;
  font-size: 22px;
}
.lx-message-warning {
  color: #eb8d01;
}
.icon-refresh {
  color: #57c5f7;
  font-size: 22px;
}
.icon-message-refresh {
  color: #57c5f7;
}
</style>
